import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import FormError from '../../../components/input/FormError/FormError'
import FormInlineButton from '../../../components/input/FormInline/FormInlineButton'
import FormInlineInput from '../../../components/input/FormInline/FormInlineInput'
import FormInlineRow from '../../../components/input/FormInline/FormInlineRow'
import { withSuccessHandler } from '../../../utils/forms/withHandlers'

const CheckBoxView = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 18px;
`

const FieldLabel = styled.span`
  font-size: 18px;
`

export const AddMailingListForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    render={({ form, handleSubmit, submitting, submitError }) => (
      <form
        onSubmit={withSuccessHandler(handleSubmit, () => {
          form.reset()
        })}
      >
        <FormInlineRow>
          <Field name="slug" placeholder="Route…" component={FormInlineInput} />

          <Field name="mailingListId" placeholder="Mailing List Id…" component={FormInlineInput} />

          <Field name="type" placeholder="Lead or Paid…" component={FormInlineInput} />

          <CheckBoxView>
            <FieldLabel>GB</FieldLabel>
            <Field name="GB" component="input" type="checkbox" defaultValue={false} />
          </CheckBoxView>

          <CheckBoxView>
            <FieldLabel>US</FieldLabel>
            <Field name="US" component="input" type="checkbox" defaultValue={false} />
          </CheckBoxView>

          <div>
            <FormInlineButton type="submit" disabled={submitting}>
              {submitting ? 'Saving…' : 'Add'}
            </FormInlineButton>
          </div>
        </FormInlineRow>
        <FormError submitting={submitting} error={submitError} />
      </form>
    )}
  />
)

AddMailingListForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
